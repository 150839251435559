<template>
  <Page>
    <template v-slot:content>
      <div
        id="aos-player"
        data-setup='{"token":"17b3353bb1892c02e3646296d6a3486e4f792ea5" , "vehicle": "GX66YCR1" }'
      ></div>
    </template>
  </Page>
</template>

<script>
import { Page } from "./../templates";

export default {
  name: "AutoOnShowTest",
  components: {
    Page,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="css"></style>
